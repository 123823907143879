.vmr-progress-spinner {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  display: inline-block;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }

  &.vmr-noop-animation {
    .vmr-progress-spinner-svg,
    .vmr-progress-spinner-circle {
      animation: none !important;
    }
  }

  .vmr-progress-spinner-svg {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    position: absolute;
    transform-origin: center center;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-name: vmr-progress-spinner-rotate;
  }

  .vmr-progress-spinner-circle {
    stroke-dashoffset: 0;
    stroke-linecap: round;
    stroke-dasharray: 89, 200;
    animation: vmr-progress-spinner-dash 1.5s ease-in-out infinite;
  }
}

@keyframes vmr-progress-spinner-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes vmr-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
